import type { ContentType, SectionType } from './content'

export const CONTENT_TYPE_MAPPING = {
  1: 'Folder',
  74: 'Page',
  68: 'Section',
} as const satisfies Record<ContentType, string>

export const SECTION_TYPE_MAPPING = {
  1: 'Standard',
} as const satisfies Record<SectionType, string>
