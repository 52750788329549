import { array, type InferOutput, number, object, pipe, transform } from 'valibot'
import { baseContentSchema } from './content'
import { locationSchema } from './location'

export const resultSchema = object({
  count: number(),
  time: number(),
  maxScore: number(),
  searchHits: object({
    searchHit: array(
      object({
        value: object({
          Location: locationSchema,
        }),
      }),
    ),
  }),
})

export type SearchResult = InferOutput<typeof resultSchema>

export const searchSchema = pipe(
  object({
    View: object({
      Result: resultSchema,
    }),
  }),
  transform((it) => {
    return it.View.Result.searchHits.searchHit.map((rawHit) => {
      const hit = rawHit.value.Location

      return ({
        title: hit.content.title,
        pathString: hit.pathString,
        sortField: hit.sortField,
        sortOrder: hit.sortOrder,
        contentId: hit.content.id,
        remoteId: hit.content.remoteId,
        publishedDate: hit.content.publishedDate,
        mainLocationId: hit.content.mainLocationId,
        parentLocationId: hit.parentLocationId,
        contentType: hit.content.contentType,
        sectionType: hit.content.sectionType,
        shortTitle: hit.content.fields.shortTitle,
        intro: hit.content.fields.intro,
        languageCodes: hit.content.info.languageCodes,
        childCount: hit.childCount,
        image: hit.content.thumbnail && !hit.content.thumbnail.includes('ibexaadminui') ? { uri: hit.content.thumbnail, alt: hit.content.fields.alternativText } : null,
      })
    })
  }),
  array(baseContentSchema),
)

export type Search = InferOutput<typeof searchSchema>
