import type { IbexaParams } from '~/utils/ibexa/params'
import { parse } from 'valibot'
import { searchSchema } from '~/utils/ibexa/search'

function fetchFromIbexa(params: IbexaParams) {
  const url = new URL(
    useRuntimeConfig().public.searchApiUrl,
  )

  for (const [key, value] of Object.entries(params)) {
    const values = Array.isArray(value) ? value : [value]

    for (const val of values) {
      url.searchParams.append(key, val.toString())
    }
  }

  return $fetch(url.href, {
    headers: {
      Accept: 'application/vnd.ibexa.api.ViewInput+json',
    },
  })
}

export default async function useIbexaContent(params: IbexaParams) {
  const query = useQuery({
    queryKey: ['object', params],
    queryFn: async () => {
      const res = await fetchFromIbexa(params)
      const result = parse(searchSchema, res)
      return result
    },
  })

  await query.suspense()

  return query
}
