import { array, fallback, type InferOutput, nullish, number, object, picklist, pipe, string, transform } from 'valibot'
import { baseFieldSchema } from './field'

export const versionInfoSchema = object({
  id: number(),
  versionNo: number(),
  status: picklist([
    'DRAFT',
    'PUBLISHED',
    'ARCHIVED',
  ]),
  modificationDate: string(),
  creationDate: string(),
  initialLanguageCode: picklist([
    'nor-NO',
    'nno-NO',
  ]),
  languageCodes: string(),
})

export const versionSchema = pipe(
  object({
    VersionInfo: versionInfoSchema,
    Fields: object({
      field: array(baseFieldSchema),
    }),
    Thumbnail: fallback(
      nullish(
        object({
          resource: string(),
        }),
      ),
      null,
    ),
  }),
  transform(it => ({
    fields: it.Fields.field,
    info: it.VersionInfo,
    thumbnail: it.Thumbnail?.resource ?? null,
  })),
)

export type Version = InferOutput<typeof versionSchema>
