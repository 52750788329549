import { array, boolean, type InferOutput, intersect, literal, nullish, number, object, picklist, string, union } from 'valibot'

/**
 * Images
 *
 */
export const imageVariationSchema = object({ href: string() })

export const imageData = object({
  source: nullish(string()),
  alternativeText: nullish(string()),
  destinationContentId: nullish(string()),
  variations: nullish(object({
    original: imageVariationSchema,
    gallery: imageVariationSchema,
    large: imageVariationSchema,
    medium: imageVariationSchema,
    small: imageVariationSchema,
    tiny: imageVariationSchema,
    reference: imageVariationSchema,
  })),
})

export type ImageData = InferOutput<typeof imageData>

export const imageField = object({
  fieldTypeIdentifier: union([
    literal('ezimage'),
    literal('ezimageasset'),
  ]),
  fieldValue: imageData,
})

/***
 * Metadata
 *
 */
export const metaData = object({
  contentItemId: string(),
  elementURI: string(),
  rdfResource: string(),
  mimeType: string(),
  docType: string(),
  materialID: nullish(string()),
  pageID: nullish(string()),
  cmid: nullish(string()),
  embedURL: nullish(string()),
  embedCode: nullish(string()),
  image: nullish(string()),
  duration: nullish(string()),
})

export type MetaData = InferOutput<typeof metaData>

export const metadataField = object({
  fieldTypeIdentifier: literal('metadata'),
  fieldValue: metaData,
})

/**
 * RichText
 */
export const richTextData = object({
  xml: string(),
  xhtml5output: nullish(string()),
  xhtml5edit: nullish(string()),
})

export type RichTextData = InferOutput<typeof richTextData>

export const richTextField = object({
  fieldTypeIdentifier: literal('ezrichtext'),
  fieldValue: richTextData,
})

/**
 * Boolean
 */
export const booleanData = boolean()

export type BooleanData = InferOutput<typeof booleanData>

export const booleanField = object({
  fieldTypeIdentifier: literal('ezboolean'),
  fieldValue: booleanData,
})

/**
 * Numeric
 */
export const numericData = number()

export type NumericData = InferOutput<typeof numericData>

export const numericField = object({
  fieldTypeIdentifier: literal('numeric'),
  fieldValue: union([
    numericData,
    array(numericData),
  ]),
})

/**
 * String
 */
export const stringData = string()

export type StringData = InferOutput<typeof stringData>

export const stringField = object({
  fieldTypeIdentifier: union([
    literal('ezstring'),
    literal('eztext'),
  ]),
  fieldValue: union([
    nullish(stringData),
    array(stringData),
  ]),
})

export const fieldValues = union([
  imageField,
  metadataField,
  richTextField,
  booleanField,
  numericField,
  stringField,
])

export type FieldValue = InferOutput<typeof fieldValues>

export const baseFieldSchema = intersect([
  object({
    id: number(),
    fieldDefinitionIdentifier: picklist([
      'intro',
      'short_title',
      'image_asset',
      'title',
    ]),
    languageCode: picklist(['nor-NO', 'nno-NO']),
  }),
  fieldValues,
])

export type BaseField = InferOutput<typeof baseFieldSchema>
