import type { BaseField, BooleanData, MetaData, NumericData, RichTextData, StringData } from './field'

export type RawField =
  | ImageData
  | MetaData
  | RichTextData
  | NumericData
  | StringData
  | BooleanData
  | { [key: string]: RawField }
  | Array<RawField | undefined>
  | null

export type NormalizedField = Record<string, RawField>

/**
 * Normalize a content's field by unwrapping it based on its type.
 *
 * @param field - Content part of a raw Ibexa field
 */

export function getFieldValue(field: BaseField): RawField | undefined {
  switch (field.fieldTypeIdentifier) {
    case 'ezrichtext':
      return (field.fieldValue?.xhtml5edit || field.fieldValue?.xhtml5output || field.fieldValue?.xml || '').replace('<?xml version="1.0" encoding="UTF-8"?>\n', '').trim()
    case 'ezstring':
    case 'eztext':
      return field.fieldValue
    case 'ezboolean':
      return field.fieldValue
    case 'metadata':
      return field.fieldValue
    case 'numeric':
      return field.fieldValue
    case 'ezimage':
    case 'ezimageasset':
      return field.fieldValue
    default:
      return null
  }
}

/**
 * Transform an array of fields to an object keyed by the field' defintion identifier and with their contents normalized.
 *
 * @param fields - Array of raw Ibexa fields
 */
export function transformFields(fields: BaseField[]) {
  return fields.reduce<NormalizedField>((acc, field) => ({
    ...acc,
    [toCamelCase(field.fieldDefinitionIdentifier, '_')]: getFieldValue(field) ?? null,
  }), {})
}
