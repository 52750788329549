import { fallback, type InferOutput, nullish, number, object, picklist, pipe, string, transform } from 'valibot'
import { contentTypeSchema, sectionTypeSchema } from './content'
import { CONTENT_TYPE_MAPPING, SECTION_TYPE_MAPPING } from './content-utils'
import * as fieldUtils from './field-utils'
import { hrefToIdSchema } from './href'
import { versionSchema } from './version'

export const contentSchema = pipe(
  object({
    _id: number(),
    _remoteId: string(),
    Name: string(),
    TranslatedName: string(),
    publishedDate: string(),
    MainLocation: fallback(
      nullish(
        hrefToIdSchema,
      ),
      null,
    ),
    Section: pipe(
      hrefToIdSchema,
      sectionTypeSchema,
    ),
    CurrentVersion: object({
      Version: versionSchema,
    }),
    ContentType: pipe(
      hrefToIdSchema,
      contentTypeSchema,
    ),
  }),
  transform(it => ({
    id: it._id,
    remoteId: it._remoteId,
    title: it.TranslatedName,
    publishedDate: it.publishedDate,
    mainLocationId: it.MainLocation,
    sectionType: SECTION_TYPE_MAPPING[it.Section] ?? null,
    contentType: CONTENT_TYPE_MAPPING[it.ContentType] ?? null,
    fields: fieldUtils.transformFields(it.CurrentVersion.Version.fields),
    info: it.CurrentVersion.Version.info,
    thumbnail: it.CurrentVersion.Version.thumbnail,
  })),
)

export const locationSchema = pipe(
  object({
    id: number(),
    childCount: number(),
    pathString: string(),
    sortField: picklist([
      'SCORE',
      'PRIORITY',
      'NAME',
      'PUBLISHED',
      'MODIFIED',
    ]),
    sortOrder: picklist([
      'ASC',
      'DESC',
    ]),
    ParentLocation: hrefToIdSchema,
    ContentInfo: object({
      Content: contentSchema,
    }),
  }),
  transform(it => ({
    childCount: it.childCount,
    pathString: it.pathString,
    sortField: it.sortField,
    sortOrder: it.sortOrder,
    parentLocationId: it.ParentLocation,
    content: it.ContentInfo.Content,
  })),
)

export type ResultLocation = InferOutput<typeof locationSchema>
