import type { RequireAtLeastOne } from '#shared/params'

export enum ParamsKey {
  LocationId = 'locationId[]',
  ParentLocationId = 'parentLocationId[]',
}

export type IbexaParams = RequireAtLeastOne<{
  [ParamsKey.LocationId]: number | number[]
  [ParamsKey.ParentLocationId]: number | number[]
}>
