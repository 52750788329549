import { fallback, type InferOutput, literal, nullable, number, object, picklist, string, union } from 'valibot'

export const sectionTypeSchema = picklist([
  '1',
])

export type SectionType = InferOutput<typeof sectionTypeSchema>

export const contentTypeSchema = picklist([
  '1',
  '74',
  '68',
])

export type ContentType = InferOutput<typeof contentTypeSchema>

export const baseContentSchema = object({
  title: string(),
  shortTitle: fallback(string(), ''),
  contentId: number(),
  remoteId: string(),
  mainLocationId: string(),
  parentLocationId: string(),
  contentType: picklist([
    'Folder',
    'Page',
    'Section',
  ]),
  sectionType: picklist([
    'Standard',
  ]),
  pathString: string(),
  publishedDate: string(),
  intro: fallback(string(), ''),
  languageCodes: union([
    literal('nor-NO'),
    literal('nno-NO'),
  ]),
  sortField: picklist([
    'SCORE',
    'PRIORITY',
    'NAME',
    'PUBLISHED',
    'MODIFIED',
  ]),
  sortOrder: picklist([
    'ASC',
    'DESC',
  ]),
  childCount: number(),
  image: fallback(
    nullable(object({
      uri: string(),
      alt: fallback(string(), ''),
    })),
    null,
  ),
})
